import React from 'react'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const FindNextTrading = () => {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        overflow: 'hidden',
        paddingBottom:'52px',
        marginY: {
          xs: 5,
          md: 0,
        },
      }}
    >
      <Typography
        data-aos='fade-right'
        data-aos-duration='1500'
        sx={{
          fontWeight: 'bold',
          fontSize: 32,
          mt: 2,
          cursor:'default'
        }}
        onClick={()=> navigate('/futures/trade/XBTUSDTM')}
         component='h3'
      >
        Seamless Onboarding
      </Typography>
      <Typography
        data-aos='fade-right'
        data-aos-duration='1500'
        sx={{
          fontSize: 14,
          marginBottom: '30px',
          paddingBottom: '10px'
        }}
      >
        Discover the cryptos that are making significant <br />
        gains in the market today
      </Typography>

      <Box
        data-aos='fade-up'
        data-aos-duration='1500'
        sx={{
          display: {
            md: 'flex',
            alignItems: 'center',
            gap: 80,
          },
        }}
      >
        <Box
          sx={{
            maxWidth: { md: '50%' },
            marginBottom: { xs: 10, md: 0 },
          }}
        >
          <img className='img-fluid' src='/pc.png' alt='' />
        </Box>
        <Box>
          {[
            {
              title: 'Start Your Trading Journey',
              detail1: 'Register Now for Seamless Crypto Transactions',
              detail2: ' and Exciting Opportunities!',
            },
            {
              title: 'Finish Your KYC Verification',
              detail1: 'Complete Your KYC Verification for ',
              detail2: ' Unlimited Trading Potential!',
            },
            {
              title: 'Quick Fiat Deposit',
              detail1: 'Link Your Bank Account for INR ',
              detail2: ' Deposits and Instant Crypto Access',
            },
          ].map((item, i) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  gap: 3,
                  marginBottom: '20px',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    bgcolor: i === 0 ? '#21CA50' : '#EFEFEF',
                    height: '150px',
                    width: '15px',
                  }}
                />
                <Box>
                  <Typography
                    sx={{
                      fontSize: {xs:24, sm:26, md:28, xl:32},
                      fontWeight: 'bold',
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography>{item.detail1}</Typography>
                  <Typography>{item.detail2}</Typography>
                </Box>
              </Box>
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}

export default FindNextTrading
