import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { FaGooglePlay } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const ImageCarousel = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length)
    }, 1500)

    return () => clearInterval(interval)
  }, [images])

  return (
    <Box
      sx={{
        overflow: 'hidden',
        // backgroundImage: 'url("/assets/banner/bg.png")',
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'center',
        background:'linear-gradient(90deg, #FFFFFF 0%, #21CA50 100%)',
        width: { xs: '100%', md: '50%' },
        position: 'relative',
        height: '90vh',
        marginTop: {
          xs: 5,
          md: 0,
        },
      }}
      className='image-carousel '
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <img
          data-aos='fade-left'
          data-aos-duration='500'
          className='img-fluid'
          src={images[currentImageIndex]}
          alt='carousel'
        />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {/* <Typography
          sx={{
            color: 'white',
            fontWeight: 'bold',
            fontSize: { xs: 20, md: 25 },
            mb: 2,
            marginLeft: { xs: 0, md: -3 },
          }}
        >
          FUTURES TRADING
        </Typography> */}
        <h2 className='img-title-banner'>FUTURES TRADING</h2>
        <Box
          sx={{
            gap: 3,
            display: 'flex',
            alignItems: 'end',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <img
            data-aos='fade-up'
            data-aos-duration='500'
            className='img-banner'
            src='/assets/banner/phone.png'
            alt=''
          />
          <a
            target='_blank'
            rel='noopener noreferrer'
            // data-aos='fade-right'
            // data-aos-duration='500'
            href='https://play.google.com/store/apps/details?id=com.app.unitic&hl=en-IN'
          >
            <img height={50} width={180} src='/assets/play_button.png' alt='' />
          </a>
        </Box>
      </Box>
      {/* <Box
        sx={{
          position: 'absolute',
          top: '75%',
          left: '70%',
          transform: 'translate(-50%, -50%)',
          color: 'white',
        }}
      >
        <a
          href='https://play.google.com/store/apps/details?id=com.app.unitic&hl=en-IN'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img height={180} width={200} src='/play_button.png' alt='' />
        </a>
      </Box> */}
      {/* <Box
        sx={{
          position: 'absolute',
          top: '75%',
          left: '70%',
          transform: 'translate(-50%, -50%)',
          color: 'white',
        }}
      >
        <div
          style={{
            background: '#CBF2DC',
            padding: '8px 16px',
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <a
            href='https://play.google.com/store/apps/details?id=com.app.unitic&hl=en-IN'
            target='_blank'
            rel='noopener noreferrer'
            style={{
              color: '#21CA50',
            }}
          >
            <FaGooglePlay fontSize={20} />
            <span style={{ fontWeight: 'bold', marginLeft: '10px' }}>
              Download
            </span>
          </a>
        </div>
      </Box> */}
    </Box>
  )
}

export default ImageCarousel
