import React from 'react';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RedditIcon from '@mui/icons-material/Reddit';
import { FaDiscord } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useContextData } from '../../core/context/index'

const FooterChild = () => {
    const navigate = useNavigate();
    const { siteSettings } = useContextData();
    const year = new Date().getFullYear();
    return (
        <section className='bg-default text-light'>
            <div className='pt-3'>
                <div style={{ backgroundColor: '#151A27' }} className='mb-2'>
                    <div className='container'>

                        <div className='row pb-1 mx-auto gx-2 gy-2' >
                            <div className='col-6 col-sm-6 col-md-2'> {
                                (siteSettings) && (
                                    <img className='img-fluid' id='navbar-img' src={siteSettings.siteLogo} alt="logo" />
                                )
                            }</div>
                            <div className='col-6 col-sm-6 col-md-2'>
                            </div>
                            <div className='col-6 col-sm-6 col-md-2'>
                            </div>
                            <div className='col-6 col-sm-6 col-md-2'>
                                <h5 className=' mb-0 pt-2'>Your <span className='text-warning fw-bold'>Crypto</span> Gateway</h5>
                            </div>
                            <div className='col-6 col-sm-6 col-md-2'>
                            </div>
                            <div className='col-6 col-sm-6 col-md-2'>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row  mx-auto gx-2 gy-3 fontSizeFooter'>
                        <div className='col-6 col-sm-6 col-md-2'>
                            <p className='font-weight-900  pb-2 text-logo-grad' >Corporate</p>
                            <ul className='p-0'>
                                <a href="javascript:void(0)" style={{ color: "white" }} onClick={() => navigate('/about')}><li>About Us</li></a>
                                <a href="javascript:void(0)" style={{ color: "white" }} onClick={() => navigate('/my/referral')}><li>Referrals</li></a>
                                <a href="javascript:void(0)" style={{ color: "white" }} onClick={() => navigate('/terms')}><li>Terms Of Use</li></a>
                                <a href="javascript:void(0)" style={{ color: "white" }} onClick={() => { navigate('/privacypolicy') }}><li>Privacy Policy</li></a>
                                <a href="javascript:void(0)" style={{ color: "white" }} onClick={() => navigate('/faq')}><li>FAQ</li></a>
                                <a href="javascript:void(0)" style={{ color: "white" }} onClick={() => navigate('/blogs')}><li>Blogs</li></a>
                            </ul>
                        </div>
                        <div className='col-6 col-sm-6 col-md-2'>
                            <p className='font-weight-900  pb-2 text-logo-grad' >Products</p>
                            <ul className='p-0'>
                                <a href="javascript:void(0)" style={{ color: "white" }} onClick={() => navigate('/spot/TRX_INR')}><li>Spot Trading</li></a>
                                <a href="javascript:void(0)" style={{ color: "white" }} onClick={() => { navigate("/futures/trade/XBTUSDTM") }}><li>Futures Trade</li></a>
                                <a href="javascript:void(0)" style={{ color: "white" }} onClick={() => { navigate('/spot/UNITIC_INR') }}><li>UNITIC TOKEN</li></a>
                                <a href="https://utistaking.com/" target='_blank' style={{ color: "white" }} ><li>Staking</li></a>
                            </ul>
                        </div>
                        <div className='col-6 col-sm-6 col-md-2'>
                            <p className='font-weight-900  pb-2 text-logo-grad' >Services</p>
                            <ul className='p-0'>
                                <a href="javascript:void(0)" style={{ color: "white" }} onClick={() => navigate('/help-center')}><li>Help Center</li></a>
                                <a href="https://www.uniticexchange.com/sitemap.xml" target='_blank' style={{ color: "white" }}><li>Sitemap</li></a>
                                <a href="javascript:void(0)" style={{ color: "white" }} onClick={() => { navigate('/help-center') }}><li>Technical Support</li></a>
                            </ul>
                        </div>
                        <div className='col-6 col-sm-6 col-md-2'>
                            <p className='font-weight-900  pb-2 text-logo-grad' >Business</p>
                            <ul className='p-0'>
                                <a href="javascript:void(0)" style={{ color: "white" }} onClick={() => { navigate('/otc_desk') }}><li>OTC Desk</li></a>
                                <a href="javascript:void(0)" style={{ color: "white" }} onClick={() => { navigate('/help-center') }}><li>Token Listing</li></a>
                                <a href="javascript:void(0)" style={{ color: "white" }} onClick={() => { navigate('/fees') }}><li>Fees</li></a>
                            </ul>
                        </div>
                        <div className='col-6 col-sm-6 col-md-2'>
                            <p className='font-weight-900  pb-2 text-logo-grad' >Developer</p>
                            <ul className='p-0'>
                                <a href="javascript:void(0)" style={{ color: "white" }} onClick={() => { navigate('/api-docs') }}><li>API Documentation</li></a>
                            </ul>
                        </div>
                        <div className='col-6 col-sm-6 col-md-2'>
                            <p className='font-weight-900  pb-2 text-logo-grad' >Social media</p>
                            <div className='row' style={{ gap: '10px' }}>
                                <div className='col-2' style={{ cursor: 'pointer' }}><a style={{ color: 'white' }} className='text-decoration-none' href="https://twitter.com/unitic__" target='_blank' rel="noreferrer" > <TwitterIcon /></a></div>
                                <div className='col-2' style={{ cursor: 'pointer' }}><a style={{ color: 'white' }} className='text-decoration-none' href="https://t.me/uniticexchange" target='_blank' rel="noreferrer" > <TelegramIcon /></a></div>
                                <div className='col-2' style={{ cursor: 'pointer' }}><a style={{ color: 'white' }} className='text-decoration-none' href="https://www.instagram.com/uniticexchange/" target='_blank' rel="noreferrer" > <InstagramIcon /></a></div>
                                <div className='col-2' style={{ cursor: 'pointer' }}><a style={{ color: 'white' }} className='text-decoration-none' href="https://www.youtube.com/@uniticexchange" target='_blank' rel="noreferrer" > <YouTubeIcon /></a></div>
                                <div className='col-2' style={{ cursor: 'pointer' }}><a style={{ color: 'white' }} className='text-decoration-none' href="https://discord.com/invite/pG4hYGcS " target='_blank' rel="noreferrer" > <FaDiscord style={{ fontSize: '24px' }} /></a></div>
                                <div className='col-2' style={{ cursor: 'pointer' }}><a style={{ color: 'white' }} className='text-decoration-none' href=" https://www.linkedin.com/company/unitic-exchange/" target='_blank' rel="noreferrer" > <LinkedInIcon /></a></div>
                                <div className='col-2' style={{ cursor: 'pointer' }}><a style={{ color: 'white' }} className='text-decoration-none' href="https://www.reddit.com/r/uniticexchange_/" target='_blank' rel="noreferrer" > <RedditIcon /></a></div>
                                <div className='col-2' style={{ cursor: 'pointer' }}><a style={{ color: 'white' }} className='text-decoration-none' href="https://www.facebook.com/uniticexchange" target='_blank' rel="noreferrer" > <FacebookRoundedIcon /></a></div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div className='container reduceSpaceFooter'>
                <hr />
                <div className='row pb-3  align-items-start'>

                    <div className='col'>
                        {
                            (siteSettings) && (
                                <p className=' very-small-text-2 mb-0 '>{siteSettings.siteName} </p>
                            )
                        }
                    </div>
                    <div className='col'>

                        <p className='very-small-text-2 mb-0 text-end'>All rights reserved {year}</p>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default FooterChild;