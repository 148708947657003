import React, { useEffect, useState } from "react";
import './searchbar.css';
import SearchIcon from '@mui/icons-material/Search';
import { AiOutlineSearch } from 'react-icons/ai';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "../../core/helper/cookie";
import { toast } from "../../core/lib/toastAlert";
import { showPairName } from '../../core/helper/date-format';
import '../../assets/style.css';
import { makeRequest } from "../../core/services/v1/request";
import Config from "../../core/config";
import socketIOClient from "socket.io-client";

const SearchBar = (props) => {
    const currentUrl = window.location.href;
    const dynamicClass = (currentUrl === "http://localhost:3000/" || currentUrl === "https://uniticexchange.com/") ? "Home" : "";
    const [isActive, setActive] = useState(false);
    const [searchVal, setsearchVal] = useState("UNITIC");
    const [markets, setMarkets] = useState({});
    const [foc, setFoc] = useState(false);
    const navigate = useNavigate();

    async function getMarkets() {
        try {
            const params = {
                url: `${Config.V1_API_URL}trade/getMarkets`,
                method: 'GET',
            }
            const response = (await makeRequest(params));
            if (response.status) {
                const filterData = (response?.data).filter(data => (data?.pair).includes(searchVal));
                setMarkets(() => response.data);
            }
        } catch (err) { }
    }

    useEffect(() => {
        getMarkets();
    }, []);

    const handleNavigation = (pair) => {
        navigate(`/spot/${pair}`);
    };
    return (
        <div style={{ position: 'relative' }}>
            <div className={`d-flex justify-content-start align-items-center ${(currentUrl === "http://localhost:3000/" || currentUrl === "https://uniticexchange.com/") ? 'borderPart' : ''}`}>
                <button className={`searchBtn${dynamicClass}`}><SearchIcon /></button>
                <input type="text" className={`${foc ? `text-default${dynamicClass}` : `text-gray${dynamicClass}`} searchGlass${dynamicClass}`} placeholder="UTI" onFocus={() => { setFoc(() => true); }} onBlur={() => {
                    setTimeout(() => {
                        setFoc(() => false);
                    }, 200);
                }} value={searchVal} onChange={(e) => {
                    // console.log("val", e.target.value);
                    setsearchVal(e.target.value);
                }} />
            </div>
            <div className={`searchDropdown${dynamicClass} ${foc ? `onFocMode${dynamicClass}` : `onBlurMode${dynamicClass}`}`}>
                <div className="row">
                    {
                        markets && markets.length > 0 && markets.map((market, i) => {
                            const searchValue = (searchVal && searchVal != "") ? searchVal.toUpperCase() : "";
                            if (market && market.pair) {
                                if (market?.toCurrency?.currencySymbol === 'UTI') return null;
                                const pairNameShow = showPairName(market.pair);
                                if ((searchValue !== "" && pairNameShow.indexOf(searchValue) > -1) || searchValue === "") {
                                    return (
                                        <div className={`col-12 hoverMode${dynamicClass}`} key={i} onClick={(e) => { e.preventDefault(); handleNavigation(market.pair); }}>
                                            <p className="curPointer" >
                                                {pairNameShow}{" "}
                                            </p>
                                        </div>
                                    )
                                }
                            }
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default SearchBar;