import React from 'react'
import { Typography, Grid, Box } from '@mui/material'

const UTIStrengthen = () => {
  return (
    <Box
      sx={{
        overflow: 'hidden',
      }}
    >
      <Grid
        sx={{
          overflow: 'hidden',
        }}
        container
        spacing={2}
      >
        <Grid
          data-aos='fade-right'
          data-aos-duration='1500'
          item
          xs={12}
          md={7}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: 32,
              my: 2,
            }}
          >
            UTI Strengthen's Your Portfolio
          </Typography>
          <Typography
            sx={{
              fontSize: 14,
            }}
          >
            Hold UTI Token To Enjoy Benefits <br />
            UTI, Unitic Native Token
          </Typography>

          <Typography
            sx={{
              fontSize: {xs:22,xl:24},
              my: 4,
              textAlign: 'justify',
            }}
          >
            The Heart Of This Ground Breaking Ecosystem Is UTI Token, Our Exchane Native Token.
          </Typography>
          <br />
          <Typography
            sx={{
              fontSize: {xs:22,xl:24},
              textAlign: 'justify',
            }}
          >
            Step into the future of cryptocurrency with UTI, the ultimate token
            for savvy traders. Enjoy perks like reduced fees, VIP access to
            token launches, and seamless integration across Unitic's innovative
            ecosystem. Elevate your trading game today with UTI!"
          </Typography>
        </Grid>
        <Grid data-aos='fade-left' data-aos-duration='1500' item xs={12} md={5}>
          <img className='img-fluid' src='/group_ottom.png' alt=''/>
        </Grid>
      </Grid>
    </Box>
  )
}

export default UTIStrengthen
