import React, { useEffect } from 'react';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RedditIcon from '@mui/icons-material/Reddit';
import { FaDiscord } from "react-icons/fa";
import {
    BrowserRouter as Router,
    Link,
    useNavigate
} from "react-router-dom";
import { useContextData } from '../../core/context/index'

const FooterHome = () => {
    const navigate = useNavigate();
    const { siteSettings } = useContextData();
    const year = new Date().getFullYear();
    return (
        <section className='pt-3'>
                <div className=''>
                    <div style={{ backgroundColor: '' }} className='mb-2 text-dark'>
                        <div className='container'>

                            <div className='row pb-1 mx-auto gx-2 gy-2' >
                                <div className='col-6 col-sm-6 col-md-2'>
                                  
                                        <img className='img-fluid' id='navbar-img' src='./logo.png' alt="logo" /></div>
                                <div className='col-6 col-sm-6 col-md-2'>
                                </div>
                                <div className='col-6 col-sm-6 col-md-2'>
                                </div>
                                <div className='col-6 col-sm-6 col-md-2'>
                                    {/* <div className='text-center ' style={{ width: 'fit-content' }}>
                                <p className=' mb-0'>Trusted by</p><p className='text-warning fw-bold'>300+</p><p>Users</p>
                            </div> */}
                                    <h5 className=' mb-0 pt-2 fw-bold'>Your <span className='text-warning fw-bold'>Crypto</span> Gateway</h5>
                                </div>
                                <div className='col-6 col-sm-6 col-md-2'>
                                </div>
                                <div className='col-6 col-sm-6 col-md-2'>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='row  mx-auto gx-2 gy-3 fontSizeFooter extraHover'>
                            <div className='col-6 col-sm-6 col-md-2'>
                                <p className='font-weight-900  pb-2 text-logo-grad-home' >Corporate</p>
                                <ul className='p-0'>
                                    <a href="javascript:void(0)" style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/about')}><li>About Us</li></a>
                                    <a href="javascript:void(0)" style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/my/referral')}><li>Referrals</li></a>
                                    <a href="javascript:void(0)" style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/terms')}><li>Terms Of Use</li></a>
                                    <a href="javascript:void(0)" style={{ color: "black", fontWeight: 700 }} onClick={() => { navigate('/privacypolicy') }}><li>Privacy Policy</li></a>
                                    <a href="javascript:void(0)" style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/faq')}><li>FAQ</li></a>
                                    <a href="javascript:void(0)" style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/blogs')}><li>Blogs</li></a>
                                </ul>
                            </div>
                            <div className='col-6 col-sm-6 col-md-2'>
                                <p className='font-weight-900  pb-2 text-logo-grad-home' >Products</p>
                                <ul className='p-0'>
                                    <a href="javascript:void(0)" style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/spot/TRX_INR')}><li>Spot Trading</li></a>
                                    <a href="javascript:void(0)" style={{ color: "black", fontWeight: 700 }} onClick={() => { navigate("/futures/trade/XBTUSDTM")}}><li>Futures Trade</li></a>
                                    <a href="javascript:void(0)" style={{ color: "black", fontWeight: 700 }} onClick={() => { navigate('/spot/UNITIC_INR') }}><li>UNITIC TOKEN</li></a>
                                    <a href="https://utistaking.com/" target='_blank' style={{ color: "black", fontWeight: 700 }} ><li>Staking</li></a>
                                </ul>
                            </div>
                            <div className='col-6 col-sm-6 col-md-2'>
                                <p className='font-weight-900  pb-2 text-logo-grad-home' >Services</p>
                                <ul className='p-0'>
                                    <a href="javascript:void(0)" style={{ color: "black", fontWeight: 700 }} onClick={() => navigate('/help-center')}><li>Help Center</li></a>
                                    <a href="https://www.uniticexchange.com/sitemap.xml" target='_blank' style={{ color: "black", fontWeight: 700 }}><li>Sitemap</li></a>
                                    <a href="javascript:void(0)" style={{ color: "black", fontWeight: 700 }} onClick={() => { navigate('/help-center') }}><li>Technical Support</li></a>
                                </ul>
                            </div>
                            <div className='col-6 col-sm-6 col-md-2'>
                                <p className='font-weight-900  pb-2 text-logo-grad-home' >Business</p>
                                <ul className='p-0'>
                                    <a href="javascript:void(0)" style={{ color: "black", fontWeight: 700 }} onClick={() => { navigate('/otc_desk') }}><li>OTC Desk</li></a>
                                    <a href="javascript:void(0)" style={{ color: "black", fontWeight: 700 }} onClick={() => { navigate('/help-center') }}><li>Token Listing</li></a>
                                    <a href="javascript:void(0)" style={{ color: "black", fontWeight: 700 }} onClick={() => { navigate('/fees') }}><li>Fees</li></a>
                                </ul>
                            </div>
                            <div className='col-6 col-sm-6 col-md-2'>
                                <p className='font-weight-900  pb-2 text-logo-grad-home' >Developer</p>
                                <ul className='p-0'>
                                    <a href="javascript:void(0)" style={{ color: "black", fontWeight: 700 }} onClick={() => { navigate('/api-docs') }}><li>API Documentation</li></a>
                                </ul>
                            </div>
                            <div className='col-6 col-sm-6 col-md-2'>
                                <p className='font-weight-900  pb-2 text-logo-grad-home' >Social media</p>
                                <div className='row' style={{ gap: '10px' }}>
                                    <div className='col-2' style={{ cursor: 'pointer' }}><a style={{ color: 'black' }} className='text-decoration-none' href="https://twitter.com/unitic__" target='_blank' rel="noreferrer" > <TwitterIcon /></a></div>
                                    <div className='col-2' style={{ cursor: 'pointer' }}><a style={{ color: 'black' }} className='text-decoration-none' href="https://t.me/uniticexchange" target='_blank' rel="noreferrer" > <TelegramIcon /></a></div>
                                    <div className='col-2' style={{ cursor: 'pointer' }}><a style={{ color: 'black' }} className='text-decoration-none' href="https://www.instagram.com/uniticexchange/" target='_blank' rel="noreferrer" > <InstagramIcon /></a></div>
                                    <div className='col-2' style={{ cursor: 'pointer' }}><a style={{ color: 'black' }} className='text-decoration-none' href="https://www.youtube.com/@uniticexchange" target='_blank' rel="noreferrer" > <YouTubeIcon /></a></div>
                                    <div className='col-2' style={{ cursor: 'pointer' }}><a style={{ color: 'black' }} className='text-decoration-none' href="https://discord.com/invite/pG4hYGcS " target='_blank' rel="noreferrer" > <FaDiscord style={{ fontSize: '24px' }} /></a></div>
                                    <div className='col-2' style={{ cursor: 'pointer' }}><a style={{ color: 'black' }} className='text-decoration-none' href=" https://www.linkedin.com/company/unitic-exchange/" target='_blank' rel="noreferrer" > <LinkedInIcon /></a></div>
                                    <div className='col-2' style={{ cursor: 'pointer' }}><a style={{ color: 'black' }} className='text-decoration-none' href="https://www.reddit.com/r/uniticexchange_/" target='_blank' rel="noreferrer" > <RedditIcon /></a></div>
                                    <div className='col-2' style={{ cursor: 'pointer' }}><a style={{ color: 'black' }} className='text-decoration-none' href="https://www.facebook.com/uniticexchange" target='_blank' rel="noreferrer" > <FacebookRoundedIcon /></a></div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div className='container reduceSpaceFooter'>
                    <hr style={{opacity: '0.5'}}/>
                    <div className='row pb-3  align-items-start'>

                        <div className='col'>
                            {
                                (siteSettings) && (
                                    <p className=' very-small-text-2 mb-0 '>{siteSettings.siteName} </p>
                                )
                            }
                        </div>
                        <div className='col'>

                            <p className='very-small-text-2 mb-0 text-end'>All rights reserved {year}</p>
                        </div>
                    </div>
                </div>

            </section>
    );
};

export default FooterHome;