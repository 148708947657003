import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import jwt_decode from 'jwt-decode'
import ImageCarousel from './animation/ImageCarousel'
import { getCookie} from '../../core/helper/cookie'
import { useNavigate } from 'react-router-dom'
import { useContextData } from '../../core/context'

const Banner = () => {
  const images = ['/assets/banner/chart.png', '/assets/banner/chart-1.png']
  const navigate = useNavigate()
  const [isLog, setIsLog] = useState(false)
  const [selectedDays, setSelectedDays] = React.useState('1W')
  const { spotPnl, siteSettings } = useContextData()
  useEffect(() => {
    const loginChk = async () => {
      const token = getCookie('userToken')
      const decodedToken = token ? jwt_decode(token, { header: true }) : {}
      if (decodedToken.typ === 'JWT') {
        setIsLog(() => true)
      } else {
        setIsLog(() => false)
      }
    }
    loginChk()
  })

  return (
    <Box
      sx={{
        minHeight: { md: '90vh' },
      }}
    >
      <div
        className='scrolltext'
        style={{ position: 'absolute', width: '100%', zIndex: 700 }}
      >
        <marquee
          width='100%'
          direction='left'
          className='pt-2'
          style={{ color: 'black', fontWeight: 550 }}
          // height="50px"
        >
          Unitic Exchange Indian Rupee Deposit Bank Account Has Been Changed.
          Kindly Check Bank Deposit Account Number Before Transferring Money.
          Happy Trading..!!
        </marquee>
      </div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: { xs: 'column', md: 'row' },
          overflow: 'hidden',
        }}
      >
        {/* Left Section */}
        <Box
          data-aos='fade-right'
          data-aos-duration='500'
          sx={{
            width: { xs: '100%', md: '50%' },
            display: 'flex',
            paddingLeft: { xs: 4, md: 7 },
            paddingTop: { xs: 10, md: 0 },
            flexDirection: 'column',
          }}
        >
          <h1 className='bannerHeading'>The India's First </h1>
          <Typography
            sx={{
              fontSize: { xs: '40px', md: '45px', xl: '50px' },
            }}
          >
            Exchange Providing <br />{' '}
            {/* <h1 className='headingSize' style={{fontWeight: 'bold',
                color: '#21CA50'}}>
                  Spot <span style={{color:'black'}}>&</span> Futures Signals
                </h1> */}
            <Typography
              sx={{
                fontWeight: 'bold',
                color: '#21CA50',
              }}
              variant='span'
            >
              Spot
            </Typography>{' '}
            &{' '}
            <Typography
              sx={{
                fontWeight: 'bold',
                color: '#21CA50',
              }}
              variant='span'
            >
              Futures Signals
            </Typography>
          </Typography>
          {/* <Typography
            sx={{
              mt: 1,
            }}
          >
            With Expert Guidance on 300+ Coins
            <br />
            We are{" "}
            <Typography
              sx={{
                fontWeight: 'bold',
                color: '#21CA50',
              }}
              variant='span'
            >
              FIU registered & 100% compliant
            </Typography>
            with Indian regulations.
          </Typography> */}
          <Typography>
            We Are{' '}
            <Typography
              sx={{
                fontWeight: 'bold',
                color: '#21CA50',
              }}
              variant='span'
            >
              FIU (Govt. of India)
            </Typography>{' '}
            Registered Entity With{' '}
            <Typography
              sx={{
                fontWeight: 'bold',
                color: '#21CA50',
              }}
              variant='span'
            >
              REID: VA00045700
            </Typography>
          </Typography>

          <Box
            sx={{
              padding: '12px 70px',
              mt: 1,
              borderRadius: '109px',
              background:
                'radial-gradient(107.08% 85.59% at 86.3% 87.5%, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0.00) 86.18%), radial-gradient(83.94% 83.94% at 26.39% 20.83%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0.00) 69.79%, rgba(255, 255, 255, 0.00) 100%), #38C558',
              boxShadow:
                '4px 38px 62px 0px rgba(0, 0, 0, 0.10), -3px -4px 7px 0px rgba(255, 255, 255, 0.10) inset',
              color: '#fff',
              cursor: 'pointer',
              maxWidth: '200px',
              display: 'flex',
              justifyContent: 'center',
            }}
            onClick={() => {
              if (isLog) {
                navigate('/my/dashboard')
              } else {
                navigate('/register')
              }
            }}
          >
            {isLog ? 'Portfolio' : 'Register'}
          </Box>
          <Box
            sx={{
              p: 2,
              background:
                'linear-gradient(273deg, rgba(232, 255, 238, 0.52) 2.37%, rgba(146, 233, 149, 0.52) 37.54%, rgba(4, 195, 0, 0.52) 96.14%)',
              // boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              borderRadius: 2,
              width: { xs: '295px', sm: '400px' },
              marginTop: 2,
            }}
          >
            <Typography
              sx={{
                fontWeight: 'bold',
                color: '#000',
                fontSize: 20,
              }}
            >
              Signal Results
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                bgcolor: '#2E2D2D',
                borderRadius: '50px',
                width: '100%',
                textAlign: 'center',
                color: '#fff',
                marginTop: 1,
              }}
            >
              {['1W', '1M', '3M', '6M', '1Y'].map((e) => {
                return (
                  <Typography
                    sx={{
                      bgcolor: selectedDays === e && '#21CA50',
                      padding: {
                        xs: '4px 11px',
                        md: '4px 15px',
                      },
                      borderRadius: '50px',
                      cursor: 'pointer',
                    }}
                    onClick={() => setSelectedDays(e)}
                  >
                    {e}
                  </Typography>
                )
              })}
            </Box>
            <Box>
              {selectedDays === '1W' && (
                <Box
                  sx={{
                    color: '#000',
                    maxWidth: '100%',
                    marginTop: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        // fontWeight: 'bold',
                        color: '#000',
                      }}
                    >
                      Trades{' '}
                    </Box>
                    <Box
                      sx={{
                        fontWeight: 'bold',
                        color: '#000',
                      }}
                    >
                      {spotPnl?.seven?.total}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        // fontWeight: 'bold',
                        color: '#000',
                      }}
                    >
                      Avg Profit{' '}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        color: spotPnl?.seven?.avg >= 0 ? '#2FAE73' : '#ff4b5e',
                      }}
                    >
                      {spotPnl?.seven?.avg > 0
                        ? `+${spotPnl?.seven?.avg}`
                        : spotPnl?.seven?.avg}
                      %
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        // fontWeight: 'bold',
                        color: '#000',
                      }}
                    >
                      Profit & Loss
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        color: spotPnl?.seven?.pnl >= 0 ? '#2FAE73' : '#ff4b5e',
                      }}
                    >
                      {spotPnl?.seven?.pnl > 0
                        ? `+${spotPnl?.seven?.pnl}`
                        : spotPnl?.seven?.pnl}
                      %
                    </Typography>
                  </Box>
                </Box>
              )}
              {selectedDays === '1M' && (
                <Box
                  sx={{
                    color: '#000',
                    maxWidth: '100%',
                    marginTop: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        // fontWeight: 'bold',
                        color: '#000',
                      }}
                    >
                      Trades{' '}
                    </Box>
                    <Box
                      sx={{
                        fontWeight: 'bold',
                        color: '#000',
                      }}
                    >
                      {spotPnl?.thirty?.total}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        // fontWeight: 'bold',
                        color: '#000',
                      }}
                    >
                      Avg Profit{' '}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        color:
                          spotPnl?.thirty?.avg >= 0 ? '#2FAE73' : '#ff4b5e',
                      }}
                    >
                      {spotPnl?.thirty?.avg > 0
                        ? `+${spotPnl?.thirty?.avg}`
                        : spotPnl?.thirty?.avg}
                      %
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        // fontWeight: 'bold',
                        color: '#000',
                      }}
                    >
                      Profit & Loss
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        color:
                          spotPnl?.thirty?.pnl >= 0 ? '#2FAE73' : '#ff4b5e',
                      }}
                    >
                      {spotPnl?.thirty?.pnl > 0
                        ? `+${spotPnl?.thirty?.pnl}`
                        : spotPnl?.thirty?.pnl}
                      %
                    </Typography>
                  </Box>
                </Box>
              )}
              {selectedDays === '3M' && (
                <Box
                  sx={{
                    color: '#000',
                    maxWidth: '100%',
                    marginTop: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        // fontWeight: 'bold',
                        color: '#000',
                      }}
                    >
                      Trades{' '}
                    </Box>
                    <Box
                      sx={{
                        fontWeight: 'bold',
                        color: '#000',
                      }}
                    >
                      {spotPnl?.ninety?.total}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        // fontWeight: 'bold',
                        color: '#000',
                      }}
                    >
                      Avg Profit{' '}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        color:
                          spotPnl?.ninety?.avg >= 0 ? '#2FAE73' : '#ff4b5e',
                      }}
                    >
                      {spotPnl?.ninety?.avg > 0
                        ? `+${spotPnl?.ninety?.avg}`
                        : spotPnl?.ninety?.avg}
                      %
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        // fontWeight: 'bold',
                        color: '#000',
                      }}
                    >
                      Profit & Loss
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        color:
                          spotPnl?.ninety?.pnl >= 0 ? '#2FAE73' : '#ff4b5e',
                      }}
                    >
                      {spotPnl?.ninety?.pnl > 0
                        ? `+${spotPnl?.ninety?.pnl}`
                        : spotPnl?.ninety?.pnl}
                      %
                    </Typography>
                  </Box>
                </Box>
              )}
              {selectedDays === '6M' && (
                <Box
                  sx={{
                    color: '#000',
                    maxWidth: '100%',
                    marginTop: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        // fontWeight: 'bold',
                        color: '#000',
                      }}
                    >
                      Trades{' '}
                    </Box>
                    <Box
                      sx={{
                        fontWeight: 'bold',
                        color: '#000',
                      }}
                    >
                      {spotPnl?.oneEighty?.total}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        // fontWeight: 'bold',
                        color: '#000',
                      }}
                    >
                      Avg Profit{' '}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        color:
                          spotPnl?.oneEighty?.avg >= 0 ? '#2FAE73' : '#ff4b5e',
                      }}
                    >
                      {spotPnl?.oneEighty?.avg > 0
                        ? `+${spotPnl?.oneEighty?.avg}`
                        : spotPnl?.oneEighty?.avg}
                      %
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        // fontWeight: 'bold',
                        color: '#000',
                      }}
                    >
                      Profit & Loss
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        color:
                          spotPnl?.oneEighty?.pnl >= 0 ? '#2FAE73' : '#ff4b5e',
                      }}
                    >
                      {spotPnl?.oneEighty?.pnl > 0
                        ? `+${spotPnl?.oneEighty?.pnl}`
                        : spotPnl?.oneEighty?.pnl}
                      %
                    </Typography>
                  </Box>
                </Box>
              )}
              {selectedDays === '1Y' && (
                <Box
                  sx={{
                    color: '#000',
                    maxWidth: '100%',
                    marginTop: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        // fontWeight: 'bold',
                        color: '#000',
                      }}
                    >
                      Trades{' '}
                    </Box>
                    <Box
                      sx={{
                        fontWeight: 'bold',
                        color: '#000',
                      }}
                    >
                      {spotPnl?.threeHundredSixty?.total}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        // fontWeight: 'bold',
                        color: '#000',
                      }}
                    >
                      Avg Profit{' '}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        color:
                          spotPnl?.threeHundredSixty?.avg >= 0
                            ? '#2FAE73'
                            : '#ff4b5e',
                      }}
                    >
                      {spotPnl?.threeHundredSixty?.avg > 0
                        ? `+${spotPnl?.threeHundredSixty?.avg}`
                        : spotPnl?.threeHundredSixty?.avg}
                      %
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        // fontWeight: 'bold',
                        color: '#000',
                      }}
                    >
                      Profit & Loss
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        color:
                          spotPnl?.threeHundredSixty?.pnl >= 0
                            ? '#2FAE73'
                            : '#ff4b5e',
                      }}
                    >
                      {spotPnl?.threeHundredSixty?.pnl > 0
                        ? `+${spotPnl?.threeHundredSixty?.pnl}`
                        : spotPnl?.threeHundredSixty?.pnl}
                      %
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        {/* Right Section */}

        <ImageCarousel images={images} />
      </Box>
    </Box>
  )
}

export default Banner
